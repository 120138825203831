import { useEffect, useRef, useState } from "react";

export default function SmallScreenTab({ setIndexPage }) {
  const [isViewing, setIsViewing] = useState("system");
  const [sliderWidth, setSliderWidth] = useState(0);
  const [sliderPosition, setSliderPosition] = useState(0);

  const therapyRef = useRef<HTMLHeadingElement>(null);
  const blinDateRef = useRef<HTMLHeadingElement>(null);
  const matchRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    const updateSlider = () => {
      const activeRef =
        isViewing === "system"
          ? therapyRef.current
          : isViewing === "handler"
          ? blinDateRef?.current
          : matchRef.current;
      if (activeRef) {
        setSliderWidth(activeRef.offsetWidth);
        setSliderPosition(activeRef.offsetLeft);
      }
    };
    updateSlider();
  }, [isViewing]);
  return (
    <div className="relative md:hidden">
      <div className="flex items-center justify-between space-x-4  text-base pb-1 md:text-base xl:text-lg w-full ">
        <h1
          ref={therapyRef}
          onClick={() => {
            setIsViewing("system");
            setIndexPage("system");
          }}
          className={`cursor-pointer w-max text-xs text-center ${
            isViewing === "system" ? "font-bold text-[#FE6897]" : "font-medium"
          }`}
        >
          System Suggestions
        </h1>
        <h1
          ref={blinDateRef}
          onClick={() => {
            setIsViewing("handler");
            setIndexPage("handler");
          }}
          className={`cursor-pointer  w-max text-xs text-center  ${
            isViewing === "handler" ? "font-bold text-[#FE6897]" : "font-medium"
          }`}
        >
          Handler Suggestions
        </h1>
        <h1
          ref={matchRef}
          onClick={() => {
            setIsViewing("match");
            setIndexPage("match");
          }}
          className={`cursor-pointer  w-max text-xs text-center  ${
            isViewing === "match" ? "font-bold text-[#FE6897]" : "font-medium"
          }`}
        >
          Match Request
        </h1>
      </div>
      <div
        className="absolute -bottom-2 z-10 h-1 bg-[#FE6897] rounded-full transition-all duration-300 ease-in-out"
        style={{
          width: `${sliderWidth}px`,
          transform: `translateX(${sliderPosition}px)`,
        }}
      ></div>

      <div className="absolute -bottom-2 left-0 right-0 h-[4px] xl:h-[2px] bg-[#FEE5EE] rounded-full"></div>
    </div>
  );
}
