import { useMutation, useQuery } from "@apollo/client";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ACCEPT_MATCH, REJECT_MATCH } from "src/components/auth/data/mutation";
import { USER_PENDING_REQUESTS } from "src/components/auth/data/queries";
import Notifications from "src/components/Notification/notifications";
import { SideBarContext } from "src/context/SidebarProvider";
import { useUser } from "src/components/auth/hooks/UserProvider";
import { useUpdateUserLoginStatus } from "src/hooks/data/mutations";
import Loading from "src/components/Loading";
import SubscribePopUp from "src/components/SubscribePopUp";
import { Celebration, Favorite } from "@mui/icons-material";
import { toast } from "react-toastify";
import ResponsiveOverlay from "src/overlays/ResponsiveOverlay";
import { GET_USER_CONVERSATIONS } from "src/hooks/data/queries";
import LargeScreenTab from "src/components/new_home/LargeScreenTab";
import SmallScreenTab from "src/components/new_home/SmallScreenTab";
import LargeScreenPage from "src/components/new_home/LargeScreenPage";
import SmallScreenPage from "src/components/new_home/SmallScreenPage";
import { useMediaQuery } from "@mui/material";
import LinkMessageMatch from "src/components/new_home/LinkMessageMatch";

interface MUSER {
  __typename: String;
  id: String;
  occupation: String;
  last_name: String;
  profile_image_url: String;
  first_name: String;
  last_online: String;
}

const FriendRequest = () => {
  const { user: us } = useUser();
  const { singleUser } = useContext(SideBarContext);
  const [updateUserLoginStatus] = useUpdateUserLoginStatus();
  const isSmallScreen = useMediaQuery("(max-width: 768px)");
  const {
    data,
    loading: loading_user,
    refetch,
  } = useQuery(USER_PENDING_REQUESTS, {
    variables: {
      userPendingRequestsId: Number(us?.id),
      suggestedBy: "SYSTEM",
    },
    fetchPolicy: "network-only",
  });

  const { data: handlerData, refetch: refetcHandlerData } = useQuery(
    USER_PENDING_REQUESTS,
    {
      variables: {
        userPendingRequestsId: Number(us?.id),
        suggestedBy: "HANDLER",
      },
      fetchPolicy: "network-only",
    }
  );

  const [acceptMatchHandler] = useMutation(ACCEPT_MATCH);
  const [rejectMatchHandler] = useMutation(REJECT_MATCH);
  const [active, setActive] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [displayData, setDisplayData] = useState<any>([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const userRef = useRef<HTMLInputElement | null>(null);
  const [isMatched, setIsMatched] = useState(false);
  const [userMatched, setUserMatched] = useState<MUSER | null>(null);
  const [cycle, setCycle] = useState("");
  const [popUp, setPopUp] = useState<boolean>(false);
  const [indexPage, setIndexPage] = useState("system");
  const [isFullMatch, setIsFullMatch] = useState(false);
  const [referencedUser, setReferencedUser] = useState(null);

  const tabs = [
    {
      caption: "System Suggestions",
      name: "system",
      title: "Suggested by our system",
      description:
        "Feel free to explore these profiles and send  match requests to profiles that tickle your fancy",
    },
    {
      caption: "Match Requests",
      name: "match",
      title: "You are quite the hot shot",
      description: "Here's a list of people looking to match with you",
    },
    {
      caption: "Pending Requests",
      name: "pending",
      title: "Here's an update on the status of your match requests",
      description:
        "All requests that are unattended by your matches are displayed here.",
    },
  ];

  const navigate = useNavigate();

  const user = singleUser?.name;
  const mainDisplayedUsers = data?.userPendingRequests?.map?.((i) =>
    i?.user_matched?.first_name === us?.first_name &&
    i?.user_matched?.last_name === us?.last_name
      ? {
          ...i,
          user_matched: i?.user_match,
          user_match: i?.user_matched,
          user_matched_status: i?.user_match_status,
          user_match_status: i?.user_matched_status,
        }
      : i
  );

  const mainDisplayedHandlerUsers = handlerData?.userPendingRequests
    ?.map?.((i) =>
      i?.user_matched?.first_name === us?.first_name &&
      i?.user_matched?.last_name === us?.last_name
        ? {
            ...i,
            user_matched: i?.user_match,
            user_match: i?.user_matched,
            user_matched_status: i?.user_match_status,
            user_match_status: i?.user_matched_status,
          }
        : i
    )
    ?.filter?.(
      (i) =>
        (i?.user_match_status === null && i?.user_matched_status === null) ||
        (i?.user_match_status === null && i?.user_matched_status === true)
    );

  const handleMatchProfileNavigation = (
    match: any,
    status: any,
    matchId: any
  ) => {
    navigate(
      `/dashboard/request-profile/${match}?mstatus=${status}&mid=${matchId}`
    );
  };

  function handleClickOutside(event) {
    if (!userRef?.current?.contains(event.target)) setActive(true);
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [userRef]);

  const systemData = mainDisplayedUsers?.filter?.(
    (i) => i?.user_match_status === null && i?.user_matched_status === null
  );
  const matchData = mainDisplayedUsers?.filter?.(
    (i) => i?.user_match_status === null && i?.user_matched_status === true
  );
  const pendingData = mainDisplayedUsers?.filter?.(
    (i) => i?.user_match_status === true && i?.user_matched_status === null
  );
  useEffect(() => {
    if (indexPage === "system") {
      setDisplayData(systemData);
    } else if (indexPage === "pending") {
      setDisplayData(pendingData);
    } else if (indexPage === "handler") {
      setDisplayData(mainDisplayedHandlerUsers);
    } else {
      setDisplayData(matchData);
    }
  }, [indexPage, data, handlerData]);

  const { data: chatdata } = useQuery(GET_USER_CONVERSATIONS, {
    variables: {
      userId: Number(us?.id),
    },
    fetchPolicy: "cache-and-network",
  });

  const handleAcceptMatch = (prop) => {
    if (
      us.subscription.name === "basic" &&
      chatdata?.conversationsByUserId >= 2
    ) {
      setPopUp(true);
    } else {
      setActionLoading(true);
      acceptMatchHandler({
        variables: {
          payload: {
            match_id: Number(prop.id),
            user_id: Number(us.id),
          },
        },
        onCompleted: (data) => {
          refetch();
          setActionLoading(false);
          toast.success("Request sent");
          if (prop?.user_matched_status === true) {
            setCycle("complete");
          } else {
            setCycle("partial");
          }
          setUserMatched(prop?.user_matched);
          setIsMatched(true);
        },
        onError: (error) => {
          console.log(error);
          toast.error("An error occured");
          setActionLoading(false);
        },
      });
    }
  };

  const handleRejectMatch = (prop) => {
    if (
      us.subscription.name === "basic" &&
      chatdata?.conversationsByUserId >= 2
    ) {
      setPopUp(true);
    } else {
      setActionLoading(true);
      rejectMatchHandler({
        variables: {
          payload: {
            match_id: Number(prop?.id),
            user_id: Number(us.id),
          },
        },
        onCompleted: (data) => {
          refetch();
          setActionLoading(false);
        },
        onError: (error) => {
          console.log(error);
          setActionLoading(false);
          toast.error("An error occured");
        },
      });
    }
  };

  const checkEligibility = () => {
    return (
      us.subscription.name === "basic" && chatdata?.conversationsByUserId >= 2
    );
  };
  return (
    <>
   
      {/* p
      op up celeberations */}
      <ResponsiveOverlay
        title={
          cycle === "partial"
            ? "Request sent to " + userMatched?.first_name
            : "Matched with " + userMatched?.first_name
        }
        size="small"
        isOpen={isMatched}
        setIsOpen={() => {
          setUserMatched(null);
          setIsMatched(false);
          setCycle("");
        }}
      >
        {cycle === "complete" && (
          <div className="p-5 space-y-5 pb-10">
            <div className="flex justify-center items-center">
              <div className="w-20 h-20 lg:w-32 lg:h-32 rounded-full bg-orange-100 text-blue-600 flex justify-center items-center text-xl md:text-4xl">
                <Celebration fontSize="inherit" />
              </div>
            </div>
            <div className="">
              <h2 className="text-sm text-center font-bold ">
                Congratulations!!! It's a match.
              </h2>
              <p className="text-xs py-2 font-medium text-center">
                You have successfully matched with {userMatched?.first_name}{" "}
                {userMatched?.last_name}
              </p>
              <p className="text-xs text-center">
                You can take this further by dropping a direct message. We are
                rooting for you!
              </p>
            </div>
            <div className="flex flex-col justify-center items-center gap-5">
              <button
                onClick={() => {
                  navigate("/dashboard/messages");
                }}
                className="px-4 py-2 rounded-lg uppercase block w-full sm:w-1/2 bg-[#081A51] text-white border border-[#081A51] text-xs font-normal "
              >
                Message {userMatched?.first_name}
              </button>
              <button
                onClick={() => {
                  setUserMatched(null);
                  setIsMatched(false);
                  setCycle("");
                }}
                className="px-4 py-2 rounded-lg uppercase block w-full sm:w-1/2 bg-white text-[#081A51] border border-[#081A51] text-xs font-normal "
              >
                Cancel
              </button>
            </div>
          </div>
        )}
        {cycle === "partial" && (
          <div className="p-5 space-y-5 pb-10">
            <div className="flex justify-center items-center">
              <div className="w-20 h-20 lg:w-32 lg:h-32 rounded-full bg-orange-100 flex justify-center items-center text-pink-500 text-xl md:text-4xl">
                <Favorite fontSize="inherit" />
              </div>
            </div>
            <div className="">
              <h2 className="text-sm text-center font-bold ">
                Match request sent!!!
              </h2>
              <p className="text-xs py-2 font-medium text-center">
                You have sent a match request to {userMatched?.first_name}{" "}
                {userMatched?.last_name}
              </p>
              <p className="text-xs text-center"></p>
            </div>
            <div className="flex flex-col justify-center items-center gap-5">
              <button
                onClick={() => {
                  setUserMatched(null);
                  setIsMatched(false);
                  setCycle("");
                }}
                className="px-4 py-2 rounded-lg uppercase block w-full sm:w-1/2 bg-[#081A51] text-white border border-[#081A51] text-xs font-normal "
              >
                Continue
              </button>
            </div>
          </div>
        )}
      </ResponsiveOverlay>
      {/* loader */}
      {actionLoading && <Loading />}
      {loading_user ? (
        <div className="md:bg-white ">
          <div className="gap-3 p-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
            <div className="max-w-sm w-full mx-auto bg-white shadow-md rounded-lg overflow-hidden">
              <div className="animate-pulse bg-gray-200 h-24 w-full"></div>
              <div className="p-6">
                <div className="animate-pulse bg-gray-200 w-1/4 h-4 mb-2"></div>
                <div className="animate-pulse bg-gray-200 w-full h-4 mb-2"></div>
                <div className="animate-pulse bg-gray-200 w-3/4 h-4 mb-2"></div>
                <div className="animate-pulse bg-gray-200 w-1/2 h-4 mb-2"></div>
              </div>
            </div>
            <div className="max-w-sm w-full mx-auto bg-white shadow-md rounded-lg overflow-hidden">
              <div className="animate-pulse bg-gray-200 h-24 w-full"></div>
              <div className="p-6">
                <div className="animate-pulse bg-gray-200 w-1/4 h-4 mb-2"></div>
                <div className="animate-pulse bg-gray-200 w-full h-4 mb-2"></div>
                <div className="animate-pulse bg-gray-200 w-3/4 h-4 mb-2"></div>
                <div className="animate-pulse bg-gray-200 w-1/2 h-4 mb-2"></div>
              </div>
            </div>
            <div className="max-w-sm w-full mx-auto bg-white shadow-md rounded-lg overflow-hidden">
              <div className="animate-pulse bg-gray-200 h-24 w-full"></div>
              <div className="p-6">
                <div className="animate-pulse bg-gray-200 w-1/4 h-4 mb-2"></div>
                <div className="animate-pulse bg-gray-200 w-full h-4 mb-2"></div>
                <div className="animate-pulse bg-gray-200 w-3/4 h-4 mb-2"></div>
                <div className="animate-pulse bg-gray-200 w-1/2 h-4 mb-2"></div>
              </div>
            </div>
          </div>
        </div>
      ) : isSmallScreen && isFullMatch ? (
        <LinkMessageMatch
          data={referencedUser}
          isMainPage
          setIsFullMatch={setIsFullMatch}
        />
      ) : (
        <div className="space-y-5">
          <LargeScreenTab
            tabs={tabs}
            indexPage={indexPage}
            setIndexPage={setIndexPage}
            systemData={systemData}
            pendingData={pendingData}
            matchData={matchData}
          />
          <SmallScreenTab setIndexPage={setIndexPage} />
          <LargeScreenPage
            tabs={tabs}
            indexPage={indexPage}
            displayData={displayData}
            handleRejectMatch={handleRejectMatch}
            handleAcceptMatch={handleAcceptMatch}
            handleMatchProfileNavigation={handleMatchProfileNavigation}
          />
          <SmallScreenPage
            mainUsers={displayData}
            trackingPage={indexPage}
            refetchMainData={refetch}
            refetchHandlerData={refetcHandlerData}
            checkEligibility={checkEligibility}
            setPopUp={setPopUp}
            setReferencedUser={setReferencedUser}
            setIsFullMatch={setIsFullMatch}
          />
        </div>
      )}
      <Notifications
        visible={showNotifications}
        close={() => setShowNotifications(false)}
        user_id={Number(user?.id)}
      />
      {popUp && (
        <div
          style={{ zIndex: 70 }}
          className="fixed  top-0 bottom-0 left-0 right-0 bg-black bg-opacity-70 z-50 flex justify-center items-center overflow-y-auto"
        >
          <div className="flex justify-between items-center px-6  py-6">
            <SubscribePopUp setPopUp={setPopUp} />
          </div>
        </div>
      )}
    </>
  );
};

export default FriendRequest;
