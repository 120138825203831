import {
  Cancel,
  Circle,
  Close,
  Favorite,
  LocationOn,
  PlaylistAdd,
} from "@mui/icons-material";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import ASSETS from "src/asset";
import { ACCEPT_MATCH, REJECT_MATCH } from "../auth/data/mutation";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { useUser } from "../auth/hooks/UserProvider";
import axios from "axios";
import { env } from "src/helpers";

export default function SmallScreenPage({
  mainUsers,
  trackingPage,
  refetchMainData,
  refetchHandlerData,
  checkEligibility,
  setPopUp,
  setReferencedUser,
  setIsFullMatch,
}) {
  const { user: us } = useUser();
  const [acceptMatchHandler] = useMutation(ACCEPT_MATCH);
  const [rejectMatchHandler] = useMutation(REJECT_MATCH);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [showRejectIcon, setShowRejectIcon] = useState(false);
  const [showAcceptIcon, setShowAcceptIcon] = useState(false);
  const navigate = useNavigate();

  const handleAcceptMatch = (prop) => {
    setShowAcceptIcon(true);
    acceptMatchHandler({
      variables: {
        payload: {
          match_id: Number(prop.id),
          user_id: Number(us.id),
        },
      },
      onCompleted: (data) => {
        if (trackingPage === "handler") {
          refetchHandlerData?.();
        } else {
          refetchMainData?.();
        }
        setShowAcceptIcon(false);
        toast.success("Request sent");
        if (prop?.user_matched_status === true) {
          setReferencedUser(prop?.user_matched);
          setIsFullMatch(true);
        }
      },
      onError: (error) => {
        console.log(error);
        setShowAcceptIcon(false);
        toast.error("An error occured");
      },
    });
  };

  const handleRejectMatch = (prop) => {
    setShowRejectIcon(true);
    rejectMatchHandler({
      variables: {
        payload: {
          match_id: Number(prop?.id),
          user_id: Number(us?.id),
        },
      },
      onCompleted: (data) => {
        if (trackingPage === "handler") {
          refetchHandlerData?.();
        } else {
          refetchMainData?.();
        }
        setShowRejectIcon(false);
      },
      onError: (error) => {
        console.log(error);

        toast.error("An error occured");
      },
    });
  };

  const handleReject = () => {
    if (checkEligibility?.()) {
      setPopUp?.(true);
      return;
    }
    handleRejectMatch(currentUser);
  };
  const handleAccept = () => {
    if (checkEligibility?.()) {
      setPopUp?.(true);
      return;
    }

    handleAcceptMatch(currentUser);
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  const currentUser = mainUsers?.[currentIndex];
  const profilePArr = currentUser?.user_matched?.profile_image_url
    ? [currentUser?.user_matched?.profile_image_url]
    : [];
  const mediaArr =
    currentUser?.user_matched?.media?.length > 0
      ? currentUser?.user_matched?.media?.map?.((i) => i?.url)
      : [];
  const combinedArr = [...profilePArr, ...mediaArr];

  // const triggerFunc = (values: any) => {
  //   axios
  //     .post(
  //       `${env("rest_api_endpoint")}/pwa-subscription/pwa-send-notification`,
  //       values
  //     )
  //     .then((res) => {
  //       console.log(res?.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  return (
    <div className=" w-full md:hidden ">
      {currentUser ? (
        <>
          <div  onClick={() => {
                navigate(
                  `/dashboard/request-profile/${currentUser?.user_matched?.id}?mstatus=${currentUser?.user_matched_status}&mid=${currentUser?.id}`
                );
              }} className="p-1 w-full cursor-pointer">
            <div
              style={{ boxShadow: "0 15px 10px rgba(254, 204, 222, 1)" }}
              className="relative w-full tind bg-black bg-opacity-40 rounded-xl shadow-lg overflow-hidden"
            >
              {combinedArr?.length > 0 ? (
                <Slider {...sliderSettings}>
                  {combinedArr?.map?.((pic, index) => (
                    <div key={index} className="w-full relative">
                      <img
                        src={pic}
                        alt={`${currentUser.user_match?.first_name}-pic-${index}`}
                        style={{
                          height: "calc(100vh - 350px)",
                          minHeight: "450px",
                        }}
                        className="w-full h-full object-contain"
                      />
                      <div className="absolute inset-0 bg-black bg-opacity-40"></div>
                      {showRejectIcon && (
                        <div
                          className="absolute inset-0 flex items-center justify-center"
                          style={{
                            animation: "fadeInOut 1s ease",
                          }}
                        >
                          <Cancel
                            style={{
                              fontSize: "200px",
                              color: "#FF77A8",
                            }}
                          />
                        </div>
                      )}
                      {showAcceptIcon && (
                        <div
                          className="absolute inset-0 flex items-center justify-center"
                          style={{
                            animation: "fadeInOut 1.5s ease",
                          }}
                        >
                          <Favorite
                            style={{
                              fontSize: "200px",
                              color: "#FF77A8",
                            }}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </Slider>
              ) : (
                <div className="w-full relative">
                  <img
                    src={ASSETS?.Avi}
                    alt={`${currentUser.user_match?.first_name}`}
                    style={{
                      height: "calc(100vh - 350px)",
                      minHeight: "450px",
                    }}
                    className="w-full h-full object-contain"
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-40"></div>
                  {showRejectIcon && (
                    <div
                      className="absolute inset-0 flex items-center justify-center"
                      style={{
                        animation: "fadeInOut 1s ease",
                      }}
                    >
                      <Cancel
                        style={{
                          fontSize: "200px",
                          color: "#FF77A8",
                        }}
                      />
                    </div>
                  )}
                  {showAcceptIcon && (
                    <div
                      className="absolute inset-0 flex items-center justify-center"
                      style={{
                        animation: "fadeInOut 1.5s ease",
                      }}
                    >
                      <Favorite
                        style={{
                          fontSize: "200px",
                          color: "#FF77A8",
                        }}
                      />
                    </div>
                  )}
                </div>
              )}
              <div  className="absolute space-y-2 bg-transparent bottom-0 w-full text-white p-4">
                <h2 className="text-4xl flex gap-3 items-center font-bold">
                  {currentUser.user_matched?.first_name}
                  <span className="w-6 h-6 rounded-full flex justify-center items-center bg-white text-black text-xs font-medium">
                    {currentUser?.user_matched?.age?.includes?.("-")
                      ? moment().diff(
                          //@ts-ignore
                          moment(currentUser?.user_matched?.age),
                          "years"
                        )
                      : moment().diff(
                          //@ts-ignore
                          moment(currentUser?.user_matched?.age, "DD/MM/YYYY"),
                          "years"
                        )}
                  </span>
                </h2>
                <div className="flex gap-3 items-center">
                  <div className="flex gap-2 flex-shrink-0 text-sm items-center">
                    <LocationOn color="inherit" fontSize="inherit" />
                    <p className="">
                      {`${currentUser?.user_matched?.location_state}${
                        currentUser?.user_matched?.location_country
                          ? `, ${currentUser?.user_matched?.location_country}`
                          : ""
                      } `}
                    </p>
                  </div>
                  <p className="text-[10px] text-white">
                    <Circle fontSize="inherit" color="inherit" />
                  </p>
                  <p className="text-sm w-40 block truncate">
                    {currentUser?.user_matched?.occupation}
                  </p>
                </div>
                <h1 className="truncate line-clamp-2 ">{currentUser?.user_matched?.about_me}</h1>
              </div>
            </div>
          </div>
          <div className="flex space-x-4 items-center w-3/4 mx-auto justify-between mt-10">
            <div
              style={{
                boxShadow:
                  "-4px -4px 12px 0px #ED2563 inset, 4px 4px 12px 0px #FFD7E3 inset,  6px 10px 24px 0px #FF8CB333 ",
              }}
              onClick={() => {
                handleReject();
              }}
              className="w-14 h-14 rounded-full cursor-pointer shadow-inner flex justify-center bg-[#FFD6E4] text-red-600 items-center text-3xl"
            >
              <img src={ASSETS?.Rej} alt="add" className="w-6 h-6" />
            </div>
            <div
              style={{
                boxShadow:
                  " -4px -4px 12px 0px #ED2563 inset, 4px 4px 12px 0px #FFD7E3 inset, 6px 10px 24px 0px #FF8CB333",
              }}
              onClick={() => {
                handleAccept();
              }}
              className="w-20 h-20 rounded-full cursor-pointer flex justify-center bg-[#081A51] text-white items-center text-4xl"
            >
              <Favorite fontSize="inherit" />
            </div>
            <div
              style={{
                boxShadow:
                  "-4px -4px 12px 0px #ED2563 inset, 4px 4px 12px 0px #FFD7E3 inset,  6px 10px 24px 0px #FF8CB333 ",
              }}
              onClick={() => {
                navigate(
                  `/dashboard/request-profile/${currentUser?.user_matched?.id}?mstatus=${currentUser?.user_matched_status}&mid=${currentUser?.id}`
                );
              }}
              className="w-14 h-14 rounded-full cursor-pointer flex justify-center bg-[#FFD6E4] text-[#FF77A8] items-center text-3xl"
            >
              <img src={ASSETS?.Vpro} alt="add" className="w-6 h-6" />
            </div>
          </div>
        </>
      ) : (
        <div className="space-y-5 py-10">
          <div className="flex justify-center items-center">
            <div className="w-60 h-60 bg-[#FEE5EE] rounded-full flex justify-center items-center">
              <div className="w-3/5 h-3/5 rounded-full bg-[#FE6897] flex justify-center items-center">
                <div className="w-1/2 h-1/2 rounded-full bg-white p-2 flex justify-center items-center">
                  <img
                    src={ASSETS?.Avi}
                    alt="no data"
                    className="w-full h-full rounded-full"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="space-y-3 px-5 flex flex-col justify-center items-center">
            <h2 className="text-3xl font-bold text-center text-[#FE6897] ">
              You have run out of{" "}
              {trackingPage === "match"
                ? `match requests`
                : `${trackingPage} matches`}
            </h2>
            <p className="text-base text-center">
              We have run out of matches based on your preset preferences.
              Please chat with your handler for more matches
            </p>
            <button
              onClick={() => {
                navigate("/dashboard/messenger", {
                  state: {
                    pageRef: "handler",
                  },
                });
              }}
              className="px-4 py-2.5 sm:w-1/2 rounded-lg text-center w-full text-base font-medium bg-[#FE6897] text-white "
            >
              Chat with handler
            </button>
            {/* <button
              onClick={() => {
                triggerFunc({
                  userId: Number(us?.id),
                  title: "Prayer ups",
                  body: "Let it all work out",
                });
              }}
              className="px-4 py-2.5 sm:w-1/2 rounded-lg text-center w-full text-base font-medium bg-[#FE6897] text-white "
            >
              Trigger
            </button> */}
          </div>
        </div>
      )}
    </div>
  );
}
